import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { slugify, artistname } from "../../lib/utils";
import Image from "gatsby-plugin-sanity-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
// import Image from '../image'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  a {
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    &:hover {
      h4 {
        color: var(--pink);
      }
      .imagecontainer {
        border: 1px solid var(--pink);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }
  h4 {
    font-size: 19px;
    text-align: center;
    margin-top: 6px;
  }
`;

const ImageContainer = styled.div`
  width: 246px;
  height: 306px;
  position: relative;
  border: 1px solid transparent;
  transition: all 0.25s ease-in-out;
  img {
    width: 246px;
    height: 306px;
    object-fit: cover;
  }
`;

const ArtistPreview = ({ artist }) => {
  const { first_name, surname, headshot, headshot_thumbnail } = artist;

  const artist_name = artistname(artist);
  const link = "/artists/" + slugify(artist_name);

  const face = headshot_thumbnail ? headshot_thumbnail : headshot;

  return (
    <Container>
      <Link to={link}>
        <ImageContainer className="imagecontainer">
          <img loading="lazy" src={face.asset.localFile.publicURL} alt={`${first_name} ${surname}`} />
        </ImageContainer>
        <h4>
          {first_name} {surname}
        </h4>
      </Link>
    </Container>
  );
};

export default ArtistPreview;
