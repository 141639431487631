import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Title from '../components/title'
import ArtistsFilter from '../components/artists_filter'
import PageSEO from '../components/page_seo'


const PageArtists = () => {

  const [searchterm, setSearchTerm] = useState();
  const [searchtypes, setSearchTypes] = useState([]);

  return (
    <>
      <PageSEO path="/artists" />
      <Title noborder>Artists</Title>
      <ArtistsFilter searchtypes={searchtypes} setSearchTypes={setSearchTypes} searchterm={searchterm} setSearchTerm={setSearchTerm} />
    </>
  )
}

export default PageArtists;
