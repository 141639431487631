import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import styled from "styled-components";
import { slugify, artistname } from "../../lib/utils";
import ArtistsGrid from "../artists_grid";
import ArtistPreview from "../artist_preview";
import MagnifyingGlass from "../../images/magnifying_glass.svg";

const Container = styled.div`
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const ContainerSearchInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 46px;
  img {
    width: 28px;
    transform: scaleX(-1);
    margin-left: 24px;
    opacity: 0.3;
  }
`;

const ContainerSearchFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 880px) {
    display: block;
  }
  ul {
    text-align: center;
    list-style: none;
    margin: 0;
    padding-left: 0;
    @media (max-width: 880px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    li {
      display: inline-block;
      cursor: pointer;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 16px;
      &.hidden {
        display: none;
      }
      ::-moz-selection {
        /* Code for Firefox */
        background: #fff;
      }
      ::selection {
        background: #fff;
      }
      a {
        font-family: Catamaran, sans-serif;
        color: #2e2e2e;
        font-size: 20px;
        font-weight: 500;
        line-height: 19px;
        transition: all 0.25s ease-in-out;
        &:hover {
          color: var(--pink);
        }
        ::-moz-selection {
          /* Code for Firefox */
          background: #fff;
        }
        ::selection {
          background: #fff;
        }
        &.active {
          color: var(--pink);
          font-family: "Playfair Display", serif;
          font-style: italic;
          font-weight: bold;
          border-bottom: 0.5px solid var(--pink);
        }
      }
    }
  }
`;

const Letter = styled.div`
  display: flex;
  h4 {
    padding-left: 1rem;
    min-width: 3rem;
    font-weight: 400;
  }
`;

const List = styled.ul`
  list-style: none;
  padding-bottom: 1rem;
  width: 100%;
  li {
    padding-top: 4px;
    padding-bottom: 1rem;
    span {
      font-family: Catamaran, sans-serif;
      text-decoration: none;
      color: var(--text);
      font-size: 20px;
      cursor: pointer;
      transition: opacity 0.25s ease-in-out;
      font-weight: 500;
      &:hover {
        color: var(--pink);
        font-weight: 600;
      }
    }
  }
`;

const Input = styled.input`
  min-width: 420px;
  font-family: Catamaran, sans-serif;
  padding: 16px 24px 16px 24px;
  font-size: 20px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  transition: all 0.25s ease-in-out;
  outline: 0;
  text-align: center;
  font-weight: 300;
  &:focus {
    border-color: #aaa;
  }
  @media (max-width: 880px) {
    min-width: 0;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaa;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #aaa;
  }
`;

const NumResult = styled.div`
  border-bottom: 2px solid #dfdfdf;
  text-align: center;
  margin-top: 46px;
  margin-bottom: 76px;
  span {
    font-size: 20px;
    font-family: Catamaran, sans-serif;
    background-color: #fff;
    color: #555;
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    transform: translateY(15px);
  }
`;

const ArtistsListMobile = styled.div`
  display: none;
  @media (max-width: 880px) {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const ArtistsFilter = ({ searchterm, setSearchTerm, searchtypes, setSearchTypes }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(sort: { fields: [first_name, surname] }) {
        nodes {
          _id
          surname
          first_name
          categories {
            title
          }
          headshot_zoom
          headshot_thumbnail {
            ...ImageWithPreview
            asset {
              localFile(width: 488) {
                publicURL
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          headshot {
            ...ImageWithPreview
            asset {
              localFile(width: 488) {
                publicURL
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const handleTap = (e, link) => {
    // e.preventDefault();
    navigate(link);
    window.scrollTo(0, 0);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (e, artist_type) => {
    e.preventDefault();
    if (searchtypes.length > 0 && searchtypes.includes(artist_type)) {
      const updated_artists_types = searchtypes.filter((f) => f !== artist_type);
      setSearchTypes(updated_artists_types);
    } else {
      setSearchTypes([...searchtypes, artist_type]);
    }
  };

  const sanity_artists = data.allSanityArtist.nodes;

  let artists;

  if (searchtypes.length > 0) {
    // check if search types array overlaps with artist.categories => titles
    // artists = sanity_artists.filter(artist => artist.categories.map(c => c !== null ? searchtypes.includes(c.title) : false));
    artists = sanity_artists
      .map((artist) => {
        if (artist?.categories && artist.categories.length > 0) {
          const artist_categories = artist.categories.map((c) => {
            if (c?.title) {
              return c.title;
            }
          });
          let intersection = artist_categories.filter((x) => searchtypes.includes(x));
          if (intersection.length > 0) {
            return artist;
          } else {
            return false;
          }
        }
      })
      .filter(Boolean);
  } else {
    artists = sanity_artists;
  }

  if (searchterm && searchterm.length > 1) {
    artists = artists.filter((artist) => (artist.surname && artist.surname.toLowerCase().includes(searchterm.toLowerCase())) || artist.first_name.toLowerCase().includes(searchterm.toLowerCase()));
  }

  const first_letters = [...new Set(artists.map((f) => f.first_name.charAt(0)))];

  const allCategories = artists.map((artist) => artist.categories).flat();

  const categoryCounts = allCategories.reduce((countMap, category) => {
    countMap[category.title] = (countMap[category.title] || 0) + 1;
    return countMap;
  }, {});

  return (
    <>
      <Container>
        <ContainerSearchInput>
          <Input onChange={(e) => handleChange(e)} value={searchterm} placeholder="Search by an Artist's Name" /> <img src={MagnifyingGlass} alt="Search" />
        </ContainerSearchInput>

        <ContainerSearchFilter>
          <ul>
            <li className={categoryCounts.hasOwnProperty("Actor") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Actor") ? "active" : ""} onClick={(e) => handleClick(e, "Actor")}>
                Actors
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Comedian") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Comedian") ? "active" : ""} onClick={(e) => handleClick(e, "Comedian")}>
                Comedians
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Composer / Musician") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Composer / Musician") ? "active" : ""} onClick={(e) => handleClick(e, "Composer / Musician")}>
                Composer/Musicians
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Director") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Director") ? "active" : ""} onClick={(e) => handleClick(e, "Director")}>
                Directors
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Lighting Designer") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Lighting Designer") ? "active" : ""} onClick={(e) => handleClick(e, "Lighting Designer")}>
                Lighting Designers
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Performer") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Performer") ? "active" : ""} onClick={(e) => handleClick(e, "Performer")}>
                Performers
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Producer") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Producer") ? "active" : ""} onClick={(e) => handleClick(e, "Producer")}>
                Producers
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Producer / Director") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Producer / Director") ? "active" : ""} onClick={(e) => handleClick(e, "Producer / Director")}>
                Producers/Directors
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("TV / Radio Presenter") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("TV / Radio Presenter") ? "active" : ""} onClick={(e) => handleClick(e, "TV / Radio Presenter")}>
                TV/Radio Presenters
              </a>
            </li>
            <li className={categoryCounts.hasOwnProperty("Writer") ? "visible" : "hidden"}>
              <a className={searchtypes.length > 0 && searchtypes.includes("Writer") ? "active" : ""} onClick={(e) => handleClick(e, "Writer")}>
                Writers
              </a>
            </li>
          </ul>
        </ContainerSearchFilter>
      </Container>

      <NumResult>
        <span>Showing {artists.length} Artists</span>
      </NumResult>

      <ArtistsListMobile>
        {first_letters.map((a, index) => {
          const this_letter_artists = artists.filter((artist) => artist.first_name.charAt(0) === a);

          return (
            <Letter key={index}>
              <h4>{a}</h4>
              <List>
                {this_letter_artists.map((t, index) => {
                  const artist_name = artistname(t);
                  const link = "/artists/" + slugify(artist_name);
                  return (
                    <li key={index}>
                      <span onClick={(e) => handleTap(e, link)}>{artist_name}</span>
                    </li>
                  );
                })}
              </List>
            </Letter>
          );
        })}
      </ArtistsListMobile>

      <ArtistsGrid maxwidth="1140px" rowgap="34px" colgap="50px">
        {artists.map((a, index) => {
          return <ArtistPreview key={index} artist={a} />;
        })}
      </ArtistsGrid>
    </>
  );
};

export default ArtistsFilter;
