import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: ${props => props.colgap};
  grid-row-gap: ${props => props.rowgap};
  max-width: ${props => props.maxwidth};
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 39px;
  @media (max-width: 1170px) {
    max-width: calc(100vw - 2rem);
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1170px) {
    max-width: calc(100vw - 2rem);
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 880px) {
    display: none;
  }
  @media (max-width: 580px) {
    display: none;
  }
`

const ArtistsGrid = ({ children, maxwidth = '1400px', colgap = '0px', rowgap = '0px' }) => {

  return (
    <Container
      rowgap={rowgap}
      colgap={colgap}
      maxwidth={maxwidth}>
      {children}
    </Container>
  )
}

export default ArtistsGrid;
